.ant-select-auto-complete {
  &.ant-select {
    .ant-select-selection__placeholder {
      left: 1px !important;
    }
  }
}
.ant-input {
  padding: 7px 5px;
}

.ant-calendar-today-btn, .ant-calendar-time-picker-btn{
  display:none !important;
}

.ant-calendar-ok-btn {
  color: #ffffff !important;
}