// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN INPUT */

.ant-input-disabled {
  background: $gray-lighten-more-more;
  color: #74708d;
}

.ant-select-auto-complete.ant-select-sm .ant-input {
  height: 30px;
  padding: 11px 7px;
}
.ant-select-auto-complete.ant-input__placeholder {
  color: red;
}

.ant-input-sm {
  height: 30px;
}
